export default function findParrent(element, parrentSelector)
{
    const elements = [];
    let elem = element;
    const ishaveselector = parrentSelector !== undefined;

    while ((elem = elem.parentElement) !== null) {
        if (elem.nodeType !== Node.ELEMENT_NODE) {
            continue;
        }

        if (!ishaveselector || elem.matches(parrentSelector)) {
            elements.push(elem);
        }
    }

    return elements[0];
}