<template>
    <div class="polityc">
        <h1>Политика конфиденциальности</h1>

        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui at tempore corporis vel laudantium atque porro cupiditate alias impedit odit?</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui at tempore corporis vel laudantium atque porro cupiditate alias impedit odit?</p>

        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui aut cum harum corporis quis nulla tempora corrupti, deserunt delectus quos dignissimos est inventore voluptates veritatis quasi animi laboriosam repudiandae molestiae consequatur repellendus. Excepturi molestias in quod quas, expedita numquam esse cumque. Fuga inventore officiis, cum alias, voluptas dolores magni totam ut, veniam eius voluptatem. Culpa necessitatibus nisi vero corporis mollitia provident officia quo sint velit qui molestias quaerat dolor, earum, tempore, et ex laboriosam aliquid. Voluptates nemo fugit quibusdam mollitia deleniti praesentium accusamus officiis accusantium! Nulla quod unde iure repudiandae deleniti? Ut iste non illo odio recusandae assumenda suscipit voluptatem.</p>

        <h2>Lorem ipsum dolor sit amet.</h2>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui at tempore corporis vel laudantium atque porro cupiditate alias impedit odit?</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo debitis iusto ex error fugit facilis nam temporibus dolorum maiores sit, nihil consequatur modi eius molestiae. Minus velit pariatur delectus. At delectus impedit illo suscipit architecto sit, aspernatur odit ut explicabo.</p>
    </div>
</template>

<style lang="scss">
.polityc
{
    width: 100%;
    padding: 38px 15px;

    @include tablet
    {}

    @include desktop
    {}

    h1
    {
        @include font-set(24, 1.2em, 700);
        margin-bottom: 28px;
        text-transform: uppercase;

        @include tablet
        {}

        @include desktop
        {}
    }

    h2
    {
        margin-bottom: 16px;
        @include font-set(14, 1.2em, 700);
        text-transform: uppercase;

        @include tablet
        {}

        @include desktop
        {}
    }

    p
    {
        margin-bottom: 14px;
        @include font-set(14, 1.2em, 400);
        color: #fff;

        @include tablet
        {}

        @include desktop
        {}
    }
}
</style>