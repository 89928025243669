const URL = process.env.VUE_APP_URL + '/api/booking/'

export default {
	state: {
		buildings: [],
		task_id: 0,
		isRoomsNotFound: false,
		selectedRoom: null
	},
	mutations: {
		SET_BUILDINGS(state, buildings) {state.buildings = buildings},
		ADD_TO_BUILDINGS(state, build) {state.buildings.push(build)},
		SET_TASKID(state, id) {state.task_id = id;},
		SET_IS_ROOMS_NOT_FOUND(state, val) {state.isRoomsNotFound = val;},
		SET_SELECTED_ROOM(state, val) {state.selectedRoom = val;}
	},
	actions: {
		async GET_BUILDINGS({commit, getters})
		{
			const response = await fetch(URL + 'getFreeRooms/?' + new URLSearchParams({
				cost_with: getters.MIN_PRICE,
				cost_on: getters.MAX_PRICE,
				person_guid: sessionStorage.getItem('person_guid')
			}))
				.then(resp => resp.json())
			
			if (response.status === 'error')
			{
				commit('SET_IS_ROOMS_NOT_FOUND', true)
				return false
			}

			commit('SET_BUILDINGS', [])
			for (const key in response.data.buildings)
				commit('ADD_TO_BUILDINGS', response.data.buildings[key])
			commit('SET_TASKID', response.data.task_id)

			return true
		},
		async TO_BOOK({commit, state})
		{
			const person_guid = sessionStorage.getItem('person_guid');
			const room = state.selectedRoom

			const formData = new FormData();
			formData.append('taskid', state.task_id);
			formData.append('roomid', room.roomid);
			formData.append('placeid', room.placeid);
			formData.append('placeguid', room.placeguid);
			formData.append('comfortlevel', room.comfortlevel);
			formData.append('personguid', person_guid);

			const response = await fetch(URL + 'getHostel/', {
				method: 'POST',
				body: formData
			})
				.then(resp => resp.json())

			commit('SET_SUCCESS_DESCRIPTION', response.data.data.desc)

			return response
		}
	},
	getters: {
		BUILDINGS: s => s.buildings,
		TASK_ID: s => s.task_id,
		IS_ROOMS_NOT_FOUND: s => s.isRoomsNotFound
	},
}