export default {
	state: {
        isRoomsList: false,
        loading: false
	},
	mutations: {
        SET_IS_ROOMS_LIST(state, value) {state.isRoomsList = value},
        CLEAR_IS_ROOMS_LIST(state) {state.isRoomsList = false},
        SET_LOADING(state, value) {state.loading = value},
	},
	actions: {
	},
	getters: {
        IS_ROOMS_LIST: s => s.isRoomsList,
        IS_LOADING: s => s.loading,
	},
}
