<template>
    <div class="main-layout">
        <Header />
        <router-view/>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/v-header';
import Footer from '@/components/v-footer';

export default {
    name: 'v-main_layout',
    components: {
        Header,
        Footer
    },
    methods: {
        setVh()
        {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    },
    mounted()
    {
        this.setVh()
        window.addEventListener('resize', () => this.setVh())
    },
    beforeUnmount() {window.removeEventListener('resize', () => this.setVh())}
}
</script>

<style lang="scss">
.main-layout
{
    position: relative;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;

    .header__languages-list::after {display: none;}

    .header__help {display: none;}

    .footer__img--first {display: none;}
    .footer__made-in div, .footer__made-in a {color: #828282;}
    .footer__dates,
    .footer__text {color: #828282;}

    @include desktop
    {
        .header__languages-title,
        .header__languages-btn {color: #0B132B;}
        .header__languages-btn:hover {border-bottom-color: #0B132B;}
        .header__help
        {
            display: inline;
            &:hover
            {
                background-color: transparent;
                svg path {fill: #0056A6;}
                color: #0056A6;
            }
        }
        .header__languages-list::after {display: block;}

        .footer
        {
            &__col--first
            {
                max-width: 230px;
                

                span {display: inline;}
            }
        }
        .footer__dates,
        .footer__text
        {
            color: #fff;
            display: inline;
        }
    }
}
</style>
