<template>
    <div class="header container" id="header">
        <router-link to="/">
            <img src="@/assets/svg/logo.svg" class="header__logo" alt="logo">
        </router-link>

        <!-- <div class="header__select">
            <div class="header__select-title">{{locale}}</div>
            <select class="header__select" v-model="currentLang" @change="changeLang(currentLang)">
                <option
                    v-for="(lang, i) in languages"
                    :key="i"
                    :value="lang.value">{{lang.title}}</option>
            </select>
        </div> -->

        <div class="header__wrap">
            <div class="header__select-wrap">
                <div class="header__select">
                    <div class="header__select-title">{{locale}}</div>
                    <select class="header__select" v-model="currentLang" @change="changeLang(currentLang)">
                        <option
                            v-for="(lang, i) in languages"
                            :key="i"
                            :value="lang.value">{{lang.title}}</option>
                    </select>
                </div>
            </div>
            <div class="header__languages-wrap">
                <div
                    class="header__languages-title"
                    @click="toggleLangList">
                    {{locale}}
                </div>
                <ul
                    class="header__languages-list"
                    :class="{ active: isActive}">
                    <li
                        v-for="(lang, i) in languages"
                        :key="i"
                        :class="{ active: lang.value == LOCALE }"
                        @click="changeLang(lang.value)">
                        <button
                            class="header__languages-btn"
                            ref="languagesBtn">
                                {{lang.title}}
                        </button>
                    </li>
                </ul>
            </div>
            <button class="header__help" :class="{ active: isActive}" @click="openHelp">
                <img v-svg-inline src="@/assets/svg/faq.svg" alt="faq" />
                <!-- <div>Help</div> -->
                <div>{{$filters.localize('help')}}</div>
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    name: 'Header',
    computed: {
        ...mapGetters([
            'LOCALE',
        ]),
        locale() {return this.languages.find(el => el.value === this.LOCALE).title}
    },
    data() {
        return {
            languages: [
                {title: 'Русский', value: 'ru-RU'},
                {title: 'English', value: 'en-US'},
                {title: 'Español', value: 'ca_ES'},
                {title: 'Français', value: 'fr_FR'}
            ],
            currentLang: 'ru-RU',
            isActive: false,
        }
    },
    methods: {
        ...mapMutations([
            'CHANGE_LOCALE',
            'SET_IS_POPUP'
        ]),
        changeLang(locale) {
            this.CHANGE_LOCALE(locale)
        },
        toggleLangList()
        {
            if (document.body.clientWidth > 768) return

            this.isActive = !this.isActive
        },
        openHelp() {this.SET_IS_POPUP({popup: 'HelpPopup', value: true})}
    },
}
</script>

<style lang="scss">
.header
{
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    @include tablet
    {
        height: 46px;
        top: 13px;
    }

    @include desktop
    {
        height: 50px;
        top: 22px;
    }

    &__logo
    {
        width: 100px;
        @include tablet {width: 155px;}
        @include desktop {width: 171px;}
    }

    &__wrap
    {
        position: absolute;
        right: 15px;
        top: 0;
        text-align: end;

        @include tablet
        {
            text-align: center;
        }

        @include desktop
        {
            right: 31px;
            top: 11px;
        }
    }

    &__select-wrap
    {
        @include tablet
        {
            display: none;
        }
    }

    &__languages-wrap
    {
        display: none;

        @include tablet
        {
            display: block;
        }
    }

    &__languages-title
    {
        @include tablet
        {
            width: 75px;
            border: 1.5px solid $yellow;
            border-radius: 25px;
            text-align: center;
            @include font-set(12, 23px);
        }
    }

    &__languages-list
    {
        @include tablet
        {
            padding-bottom: 11px;
            position: relative;

            &::after
            {
                content: '';
                width: 35px;
                height: 2px;
                background-color: rgba(0, 121, 193, 0.25);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @include desktop
        {
            margin-bottom: 30px;
        }

        li
        {
            cursor: pointer;

            &.active {display: none;}
        }
    }

    &__languages-btn
    {
        font-size: 12px;
        line-height: 25px;
        width: 75px;
        border-radius: 25px;
        margin: 5px 0;

        &:hover {background-color: $yellow;}
    }

    &__select
    {
        width: 100px;
        height: 30px;
        position: relative;
        border: 1px solid $yellow;
        border-radius: 25px;

        select
        {
            opacity: 0;
            width: 100%;
        }

        @include tablet
        {
            display: none;
        }
    }

    &__select-title
    {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -48%);
        width: 100%;
        text-align: center;
        padding-right: 25px;
        @include font-set (13, 1.2);

        &:before,
        &:after
        {
            content: '';
            width: 9px;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 50%;
            border-radius: 2px;
            transition: all .3s ease;
        }

        &:before
        {
            right: 18px;
            transform: translateY(-50%) rotate(50deg);
        }
        &:after
        {
            right: 13px;
            transform: translateY(-50%) rotate(-50deg);
        }
    }

    &__help
    {
        color: #0079C1;
        font-size: 0;
        width: 56px;
        height: 56px;
        background-color: #0056A6;
        border-radius: 50%;
        margin-top: 22px;
        padding-bottom: 3px;

        svg path {fill: #0B1A46;}

        &:hover {background-color: #0079C1;}

        @include desktop
        {
            margin-top: 0;
            height: auto;
            background: none;
            border-radius: 0;
            font-size: 14px;

            & > div {margin-top: 7px;}

            svg path {fill: #0079C1;}

            &:hover
            {
                background-color: transparent;
                color: #0056A6;
                svg path {fill: #0056A6;}
            }
        }
    }
}
</style>