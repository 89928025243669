export default {
	state: {
		steps:  [
            {id: 1, step: 'v-price', text: 'typeStep1'},
            {id: 2, step: 'v-search', text: 'typeStep2'},
            {id: 3, step: 'v-success', text: 'typeStep3'},
        ],
        currentStep: 1,
		sessionTimeout: 900_000,
		timeStamp: 0,
		isSessionDied: false,
		status: 'success',
		timer: null,
		successDescription: '',
	},
	mutations: {
        SET_CURRENT_STEP(state, value) {state.currentStep = value},
		SET_TIMESTAMP(state, value) {state.timeStamp = value},
		SET_IS_SESSION_DIED(state, value) {state.isSessionDied = value},
		SET_STATUS(state, value) {state.status = value},
		CLEAR_ALL_FOR_SESSION(state)
		{
			state.status = 'success'
			state.timeStamp = 0
			state.isSessionDied = false
			if (state.timer) window.clearInterval(state.timer)
		},
		SET_SUCCESS_DESCRIPTION(state, desc) {state.successDescription = desc},
	},
	actions: {
		START_TIMESTAMP_CHEKER({commit, state})
		{
			state.timer = setInterval(() => {
				if (Date.now() - state['timeStamp'] > state['sessionTimeout'])
				{
					commit('SET_IS_SESSION_DIED', true)
					if (state.timer) window.clearInterval(state.timer)
				}
			}, 1000)
		}
	},
	getters: {
		STEPS: s => s.steps,
		CURRENT_STEP: s => s.currentStep,
		TIMESTAMP: s => s.timeStamp,
		STATUS: s => s.status,
		IS_SESSION_DIED: s => s.isSessionDied,
		SUCCESS_DESCRIPTION: s => s.successDescription,
	},
}
