<template>
    <div
        class="popup"
        :class="{open: isOpen}"
        ref="popup"
        :style="{backgroundColor: bgColor}"
    >
            <div class="popup__body">
                <div class="popup__content" @click.self="close()">
                    <div class="popup__close" @click="close()">
                        <div class="popup__close-btn"></div>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import findParrent from '@/services/findParrent'

export default {
    name: 'v-popup',
    data() {
        return {
            body: document.querySelector('body'),
            lockPadding: document.querySelector('.lock-padding'), // for fixed elements
            unlock: true,
            $popupActive: null,
            isOpen: false
        }
    },
    props: {
        popupName: {
            type: String,
            defailt: 'isPopup'
        },
        cbOpen: {
            type: Function,
            defailt: null
        },
        cbClose: {
            type: Function,
            defailt: null
        },
        noClose: {
            type: Boolean,
            defailt: false
        },
        timeOut: {
            type: Number,
            defailt: 1000
        },
        bgColor: {
            type: String,
            defailt: ''
        },
        // openUp: {
        //     type: Boolean,
        //     defailt: false
        // },
    },
    // watch: {
        // openUp: {
        //     immediate: true,
        //     handler(v) {
        //         console.log('openUp', v);
        //         document.documentElement.style.setProperty('--timeOut', `${this.timeOut/1000}s`);
        //         this.open()
        //         this.closeOnEsc()
        //     }
        // }
        // openUp(v) {
        //     console.log('vddsfvdf');
        // }
    // },
    methods: {
        ...mapMutations([
            'SET_IS_POPUP'
        ]),
        closeOnSwipe(e)
        {
            const startX = e.changedTouches[0].pageX
            let shiftX = 0

            const move = (e) => {
                shiftX = e.changedTouches[0].pageX - startX
                const parrentSwiper = findParrent(e.target, '.swiper-container')
                if (shiftX > 150 && !parrentSwiper)
                {
                    this.close()
                }
            }

            const theEnd = () => {
                this.$refs.popup.removeEventListener('touchmove', move);
                this.$refs.popup.removeEventListener('touchend', theEnd);
            }
            this.$refs.popup.addEventListener('touchmove', move);
            this.$refs.popup.addEventListener('touchend', theEnd);
        },
        open()
        {
            if (this.unlock) {
                this.$popupActive = document.querySelector('.popup.open')
                this.$popupActive
                    ? this.$popupActive.classList.remove('open')
                    : this.bodyLock()
                if (this.cbOpen) this.cbOpen()
                this.isOpen = true
                this.$refs.popup.addEventListener('touchstart', this.closeOnSwipe)
            }
        },
        close(doUnlock = true, force = false)
        {
            if (!force && this.noClose) return

            if (this.unlock)
            {
                this.isOpen = false
                if (doUnlock) this.bodyUnlock()
                setTimeout(() => {
                    this.SET_IS_POPUP({popup: this.popupName, value: false})
                    if (this.cbClose) this.cbClose()
                }, this.timeOut)

                if (this.$popupActive) this.$popupActive.classList.add('open')
                this.$refs.popup.removeEventListener('touchstart', this.closeOnSwipe)
            }
        },
        bodyLock()
        {
            const lockPaddingValue = window.innerWidth - this.body.offsetWidth + 'px'

            this.body.style.paddingRight = lockPaddingValue
            this.body.classList.add('lock')

            if (this.lockPadding && this.lockPadding.length)
            {
                this.lockPadding.forEach(el => el.style.paddingRight = lockPaddingValue)
            }

            this.unlock = false
            setTimeout(() => {
                this.unlock = true
            }, this.timeOut)
        },
        bodyUnlock()
        {
            this.unlock = false
            setTimeout(() => {
                if (this.lockPadding && this.lockPadding.length)
                    this.lockPadding.forEach(el => el.style.paddingRight = '0px')
                this.body.style.paddingRight = '0px'
                this.body.classList.remove('lock')

                this.unlock = true
            }, this.timeOut)
        },
        closeOnEsc()
        {
            document.addEventListener('keydown', e => {e.which === 27 && this.close()})
        }
    },
    mounted()
    {
        document.documentElement.style.setProperty('--timeOut', `${this.timeOut/1000}s`);
        this.open()
        this.closeOnEsc()
    },
    beforeUnmount()
    {
        document.removeEventListener('keydown', e => {e.which === 27 && this.close()})
        this.close(true, true)
    }
}
</script>

<style lang="scss">
.popup
{
    transform: translateX(100%);
    transition: all var(--timeOut, .3s) ease 0s;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #0B132B;
    // background-color: #0B132BB0;
    top: 0;
    left: 0;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;

    &.open
    {
        transform: translateX(0);
    }

    @include tablet {
        transform: translateX(0);
        opacity: 0;

        &.open
        {
            opacity: 1;
        }
    }

    &.top .popup__body {align-items: flex-start;}

    &__body
    {
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content
    {
        margin-top: 21px;
        border-radius: 12px;
        position: relative;

        @include tablet {
            max-width: auto;
        }
    }
    &__close
    {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 0;
        right: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;

        &:hover .popup__close-btn,
        &:active .popup__close-btn
        {
            &::before,
            &::after {background-color: #00A94F;}
        }
    }
    &__close-btn
    {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: relative;

        &::before,
        &::after
        {
            content: '';
            width: 25px;
            height: 2px;
            background-color: #CDCFD8;
            position: absolute;
            left: 50%;
            top: 50%;
        }
        &::before
        {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after
        {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    &__back
    {
        position: absolute;
        left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(46, 84, 103, 0.04), 0px 10px 16px rgba(42, 77, 95, 0.05);
        border-radius: 12px;
        cursor: pointer;
        z-index: 5;
        width: 38px;
        height: 38px;
        border-radius: 6px;

        @include tablet {
            left: 20px;
            width: 65px;
            height: 65px;
            border-radius: 12px;
        }
    }
}
</style>
