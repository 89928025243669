import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSvgInlinePlugin from "vue-svg-inline-plugin"

import localizeFilter from '@/filters/localize.filter'
import dateFilter from '@/filters/date.filter'
import currencyFilter from '@/filters/currency.filter'

import vPopup from '@/components/v-popup.vue'
import vPopupLoading from '@/components/v-popup-loading.vue'


const app = createApp(App)

app.config.globalProperties.$filters = {
  localize: (value) => localizeFilter(value),
  date: (value) => dateFilter(value),
  currency: (value) => currencyFilter(value),
}

app.use(router)
	.use(store)
	.use(VueSvgInlinePlugin)
	.component('v-popup', vPopup)
	.component('v-popup-loading', vPopupLoading)
	.mount('#app')