import { createRouter, createWebHistory } from 'vue-router';


const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            layout: 'empty',
            title: 'Главная'
        },
        component: () => import('@/views/v-home.vue')
    },
    {
        path: '/booking',
        name: 'booking',
        meta: {
            layout: 'main',
            title: 'Бронирование'
        },
        component: () => import('@/views/v-booking.vue')
    },
    {
        path: '/not-auth',
        name: 'not-auth',
        meta: {
            layout: 'empty',
            title: 'Error'
        },
        component: () => import('@/views/v-not-auth.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: {
            layout: 'empty',
            title: 'Page not found'
        },
        component: () => import('@/views/v-404.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.path === '/not-auth') return next()

    if (to.query.person_guid) sessionStorage.setItem('person_guid', to.query.person_guid)
    const person_guid = to.query.person_guid || sessionStorage.getItem('person_guid');
    person_guid
        ? next()
        : router.push('/not-auth')
})

export default router
