<template>
    <div class="v-popup-loading">
        <v-popup
            noClose
            class="top"
            :timeOut="600"
            :popupName="'popupLoading'"
        >
                <!-- :src="require('@/assets/svg/hourglass.svg')" -->
            <img
                :src="require('@/assets/img/loading.gif')"
                alt="hourglass"
                class="v-popup-loading__img">
            <div class="v-popup-loading__text">
                {{text}} <span>....</span>
            </div>
        </v-popup>
    </div>
</template>

<script>
export default {
    name: 'v-popup-loading',
    props: {
        text: {
            type: String,
            default: () => ''
        }
    },
}
</script>

<style lang="scss">
.v-popup-loading
{
    & .popup
    {
        // background-color: rgba(255, 255, 255, .95);
        background-color: rgba(253, 255, 252, 1);
        transform: translateX(0);
        opacity: 0;
        visibility: hidden;

        * {color: #0B132B;}

        &.open
        {
            opacity: 1;
            visibility: visible;
        }

        .popup__content
        {
            margin-top: 140px;
            text-align: center;
        }
        .popup__close {display: none;}
    }

    &__img
    {
    }

    &__text
    {
        margin-top: 24px;
        @include font-set (24, 1.2em, 700);
        padding: 0 15px;

        span {letter-spacing: 5px;}
    }
}
</style>
