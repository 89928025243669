<template>
	<div class="empty-layout">
		<Header />
		<router-view/>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/v-header';
import Footer from '@/components/v-footer';

export default {
	name: 'v-empty_layout',
	components: {
		Header,
		Footer
	},
	computed: {
		pageTitle() {return this.$route.meta.title}
	},
	methods: {
		setVh()
		{
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
	},
	mounted()
	{
		this.setVh()
		window.addEventListener('resize', () => this.setVh())
	},
	beforeUnmount() {window.removeEventListener('resize', () => this.setVh())}
}
</script>

<style lang="scss">
.empty-layout
{
	position: relative;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	display: flex;
	flex-direction: column;

	@supports (-webkit-appearance:none) {
		.os-android & {
		min-height: calc(100vh - 56px);
		}
	}

	.footer__img--second {display: none;}

	@include desktop
	{
		min-height: 100vh;
		display: block;
		padding: 0;
	}
}
</style>
