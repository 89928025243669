<template>
  <div class="App">
    <component :is="layout">
        <router-view/>
    </component>

    <v-popup
        v-if="HELP_POPUP"
        :popupName="'HelpPopup'"
        :timeOut="1000"
        class="top">
        <v-faq />
    </v-popup>

    <v-popup
        v-if="POLYTIC_POPUP"
        :popupName="'PilyticPopup'"
        :timeOut="1000"
        class="polytic-popup top">
        <v-polityc />
    </v-popup>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import MainLayout from '@/layouts/v-main_layout';
import EmptyLayout from '@/layouts/v-empty_layout';

import vFaq from '@/components/v-faq.vue'
import vPolityc from '@/components/v-polytic.vue'

export default {
    name: 'App',
    components: {
        MainLayout,
        EmptyLayout,
        vFaq,
        vPolityc
    },
    computed: {
        ...mapGetters([
            'HELP_POPUP',
            'POLYTIC_POPUP'
        ]),
        layout() {
            return (this.$route.meta.layout || 'empty') + '-layout'
        },
    },
}
</script>

<style lang="scss">
:root {
    color-scheme: dark;
}
.App
{
    // height: 100%;
    position: relative;

    .polytic-popup
    {
        .popup__body
        {
            @include desktop
            {
                padding: 0 18px;
            }
        }
        .popup__content
        {
            width: 100%;
            margin: 0 auto;
            max-width: 1280px;
        }

        .popup__close
        {
            top: 10px;
        }
    }
}
</style>
    