<template>
    <div class="footer container" id="footer">
        <div class="footer__col footer__col--first">
            <span class="footer__dates">© 2006-2021</span>
            <span class="footer__text">
                {{$filters.localize('copyright')}}<br>
                <a @click.prevent="showPolytic" href="#">
                    {{$filters.localize('copyrightLink')}}
                </a>
            </span>
        </div>
        <div class="footer__col footer__made-in">
            <div><a href="#" target="_blank">{{$filters.localize('madeIn')}}</a></div>
            <a href="#" target="_blank">
                <img class="footer__img--first" src="@/assets/svg/empty.svg">
            </a>
            <a href="#" target="_blank">
                <img class="footer__img--second" src="@/assets/svg/empty-black.svg">
            </a>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
    name: 'v-footer',
    methods: {
        ...mapMutations([
            'SET_IS_POPUP'
        ]),
        showPolytic() {this.SET_IS_POPUP({popup: 'PilyticPopup', value: true})}
    }
}
</script>

<style lang="scss">
.footer
{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    *
    {
        font-size: 10px;
        line-height: 1em;
        color: rgba(255, 255, 255, 0.5);
    }

    &.container
    {
        padding-bottom: 7px;

        @include tablet {padding-bottom: 16px;}

        @include desktop {padding-bottom: 19px;}
    }

    @include tablet
    {
        * {font-size: 12px;}
    }

    &__col--first
    {
        max-width: 190px;
        letter-spacing: .017em;

        a
        {
            color: #0056A6;
            text-decoration-line: underline;
        }

        @include tablet
        {
            max-width: 580px;
        }
    }

    &__dates
    {
        @include tablet {display: block;}
    }

    &__text
    {
        @include tablet
        {
            display: block;
            max-width: 345px;
        }

        @include desktop
        {
            max-width: none;
            letter-spacing: .02em;
        }
    }

    &__made-in
    {
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 3px;

        a {text-align: center;}

        & > div {margin-bottom: 4px;}

        img {width: 65px;}

        @include tablet
        {
            justify-content: flex-start;
            padding-right: 0;
            & > div {margin-bottom: 0px;}
            img {width: auto;}
        }
    }
}
</style>
