import store from '@/store'
import ru from '@/locales/ru.json'
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import sp from '@/locales/sp.json'
const locales = {
  'ru-RU': ru,
  'en-US': en,
  'fr_FR': fr,
  'ca_ES': sp
}

export default function lokalizeFilter(key) {
  const locale = store.getters.LOCALE || 'ru-RU'
  return locales[locale][key] || `[Localize error]: key ${key} not found`
}