const URL = process.env.VUE_APP_URL + '/api/booking/'

export default {
	state: {
		min_price: 0,
		max_price: 0,
		price_range: []
	},
	mutations: {
		SET_PRICE(state, {key, price})
		{
			state[key] = +price;
		},
		SET_RANGE(state, price)
		{
			state.price_range.push(+price)
		}
	},
	actions: {
		async GET_PRICES({commit})
		{
			const r = await fetch(URL + 'getPriceRange/').then(r => r.json())
			for (let key in r.data)
			{
				commit('SET_RANGE', r.data[key])
				commit('SET_PRICE', {key: key, price: r.data[key]})
			}
			return true
		}
	},
	getters: {
		MIN_PRICE: s => s.min_price,
		MAX_PRICE: s => s.max_price,
		PRICE_RANGE: s => s.price_range,
	},
}