<template>
    <div class="faq">
        <div class="faq__title">{{$filters.localize('askAQuestion')}}</div>
        <transition name="faq-wrapper">
            <div v-if="succes" class="faq__wrapper faq__wrapper-text">
                Спасибо! Ваш вопрос успешно отправлен сотрудникам общежития. Ответ будет направлен на указанный E-mail
            </div>
        </transition>
        <transition name="faq-wrapper-form">
            <div v-if="!succes" class="faq__wrapper">
                <div class="faq__desc">{{$filters.localize('helpDesc')}}.</div>
                <div class="form faq__form">

                    <div class="form__field">
                        <label for="name" class="form__label" :class="{ error: v$.form.name.$errors.length }">
                            <input 
                                class="form__input"
                                name="name"
                                type="text"
                                :placeholder="$filters.localize('FIO')"
                                v-model="form.name">
                        </label>
                    </div>

                    <div class="form__field">
                        <label for="email" class="form__label" :class="{ error: v$.form.email.$errors.length }">
                            <input
                                class="form__input"
                                name="email"
                                type="text"
                                placeholder="E-MAIL"
                                v-model="form.email">
                        </label>
                    </div>

                    <div class="form__field" :class="{ error: v$.form.text.$errors.length }">
                        <label for="text" class="form__label" :class="{ error: v$.form.text.$errors.length }">
                            <textarea
                                class="form__textarea"
                                name="text"
                                :placeholder="$filters.localize('whatIsTheProblem')+'?'"
                                v-model="form.text"></textarea>
                        </label>
                    </div>

                    <button
                        class="form__submit-btn"
                        @click="submitForm">
                            {{$filters.localize('sendRequest')}}
                            <div class="form__submit-btn-img"></div>
                        </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    name: 'v-faq',
    data() {
        return {
            v$: useVuelidate(),
            succes: false,
            form: {
                name: '',
                email: '',
                text: ''
            }
        }
    },
    validations() {
        return {
            form: {
                name: { required },
                email: { required, email },
                text: { required }
            }
        }
    },
    methods: {
        submitForm()
        {
            this.v$.$validate()
            if (this.v$.$error) return
            this.succes = true
        }
    }
}
</script>

<style lang="scss">

.faq-wrapper-enter-active,
.faq-wrapper-leave-active {
  transition: all 1s ease;
}

.faq-wrapper-form-enter-active,
.faq-wrapper-form-leave-active {
  transition: all 1s ease;
}

.faq-wrapper-enter-from,
.faq-wrapper-leave-to,
.faq-wrapper-form-enter-from,
.faq-wrapper-form-leave-to {
  opacity: 0;
  visibility: hidden;
}

.faq
{
    width: 100vw;
    max-width: 412px;
    padding: 16px;
    position: relative;

    @include desktop
    {
        max-width: 562px;
    }

    &__wrapper-text
    {
        position: absolute;
        width: calc(100% - 32px);
    }

    &__title
    {
        @include font-set(24, 1.2rem, 700);
        margin-bottom: 23px;

        @include tablet {margin-bottom: 40px;}
    }

    &__desc
    {
        @include font-set(14, 1.2rem, 400);
        margin-bottom: 32px;

        @include tablet {margin-bottom: 21px;}
    }

    .form__field
    {
        margin-bottom: 20px;
    }

    .form__submit-btn
    {
        margin-top: 40px;

        @include tablet {margin-top: 70px;}
    }
}
</style>