import { createStore } from 'vuex'
import prices from './prices'
import rooms from './rooms'
import booking from './booking'
import search from './search'

export default createStore({
  state: {
    locale: 'ru-RU',
    HelpPopup: false,
    PilyticPopup: false,
    RoomPhotoSliderPopup: false,
    currentRoom: {},
    currentCorps: {},

  },
  mutations: {
    CHANGE_LOCALE(state, locale) {state.locale = locale;},
    SET_IS_POPUP(state, {popup, value}) {state[popup] = value},
    SET_CURRENT_ROOM(state, currentRoom) {state.currentRoom = currentRoom},
    SET_CURRENT_CORPS(state, currentCorps) {state.currentCorps = currentCorps},
    CLEAR_CURRENT_ROOM(state) {state.currentRoom = {}},
    CLEAR_CURRENT_CORPS(state) {state.currentCorps = {}},
  },
  actions: {
  },
  getters: {
    LOCALE: s => s.locale,
    HELP_POPUP: s => s.HelpPopup,
    POLYTIC_POPUP: s => s.PilyticPopup,
    ROOM_PHOTO_SLIDER_POPUP: s => s.RoomPhotoSliderPopup,
    CURRENT_ROOM: s => s.currentRoom,
    CURRENT_CORPS: s => s.currentCorps
  },
  modules: {
    prices,
    rooms,
    booking,
    search
  }
})
